import axios from "axios";
import "katex/dist/katex.min.css";
import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "../../css/Blog.css";

const BlogList = ({ selectedCategory, selectedTags }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupedPosts, setGroupedPosts] = useState({});

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_API_URL}/api/blogs/`;
        const params = {};
        if (selectedCategory) params.category = selectedCategory;
        if (selectedTags?.length > 0) params.tags = selectedTags.join(",");

        const response = await axios.get(url, { params });
        setPosts(response.data);

        // Group posts by category
        const grouped = response.data.reduce((acc, post) => {
          const category = post.category_name;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(post);
          return acc;
        }, {});

        // Sort categories alphabetically
        const sortedGrouped = Object.keys(grouped)
          .sort()
          .reduce((acc, key) => {
            acc[key] = grouped[key];
            return acc;
          }, {});

        setGroupedPosts(sortedGrouped);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };
    fetchPosts();
  }, [selectedCategory, selectedTags]);

  return (
    <div>
      {Object.entries(groupedPosts).map(([category, categoryPosts]) => (
        <div key={category} className="mb-5">
          <h3 className="mb-4">{category}</h3>
          <Row>
            {categoryPosts.map((post) => (
              <Col md={6} key={post.id} className="mb-4">
                <Card className="blog-card">
                  {post.featured_image && (
                    <Link
                      to={`/blogs/${post.slug}`}
                      className="blog-card-image-link"
                    >
                      <div className="blog-card-image-container">
                        <img
                          src={post.featured_image}
                          alt={post.title}
                          className="blog-card-image"
                        />
                      </div>
                    </Link>
                  )}
                  <Card.Body className="d-flex flex-column">
                    <div className="blog-card-content">
                      <Card.Title className="blog-title">
                        <Link
                          to={`/blogs/${post.slug}`}
                          className="blog-title-link"
                        >
                          {post.title}
                        </Link>
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted blog-meta">
                        {post.category_name} •{" "}
                        {new Date(post.created_at).toLocaleDateString()} •
                        {post.read_time} min read
                      </Card.Subtitle>
                      <div className="blog-tags mb-2">
                        {post.tags.map((tag) => (
                          <Badge key={tag.id} bg="secondary" className="me-1">
                            {tag.name}
                          </Badge>
                        ))}
                      </div>
                      <Card.Text className="blog-summary">
                        <Link
                          to={`/blogs/${post.slug}`}
                          className="blog-summary-link"
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex]}
                          >
                            {post.summary}
                          </ReactMarkdown>
                        </Link>
                      </Card.Text>
                    </div>
                    <Link
                      to={`/blogs/${post.slug}`}
                      className="btn btn-primary mt-auto"
                    >
                      Read More
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
