// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Resume.css */

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-right {
  text-align: right;
}

/* Add any custom styling here to adjust the layout or appearance as needed */
`, "",{"version":3,"sources":["webpack://./src/css/Resume.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA,6EAA6E","sourcesContent":["/* Resume.css */\n\n.my-4 {\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n}\n\n.mb-4 {\n  margin-bottom: 1.5rem;\n}\n\n.mb-3 {\n  margin-bottom: 1rem;\n}\n\n.mt-4 {\n  margin-top: 1.5rem;\n}\n\n.font-weight-bold {\n  font-weight: bold;\n}\n\n.font-italic {\n  font-style: italic;\n}\n\n.text-right {\n  text-align: right;\n}\n\n/* Add any custom styling here to adjust the layout or appearance as needed */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
