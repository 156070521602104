import axios from "axios";
import "katex/dist/katex.min.css";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  const getImageUrl = (imageUrl) => {
    if (!imageUrl) return "";
    if (imageUrl.startsWith("http")) return imageUrl;
    return `${process.env.REACT_APP_BASE_API_URL}${imageUrl}`;
  };

  // Custom renderer for images in ReactMarkdown
  const renderers = {
    img: ({ src, alt }) => (
      <img src={getImageUrl(src)} alt={alt} className="img-fluid" />
    ),
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/blogs/blog-${slug}/`
        );
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching post:", error);
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <Container className="mt-4">
      <Row>
        <Col md={10} className="mx-auto">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/blogs" }}>
              Blogs
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: `/blogs?category=${post.category_slug}` }}
            >
              {post.category_name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{post.title}</Breadcrumb.Item>
          </Breadcrumb>

          {post.featured_image && (
            <img
              src={post.featured_image}
              alt={post.title}
              className="img-fluid mb-4"
            />
          )}

          <h1>{post.title}</h1>
          <div className="text-muted mb-4">
            {new Date(post.created_at).toLocaleDateString()} •{post.read_time}{" "}
            min read
          </div>

          <div className="blog-content">
            <ReactMarkdown
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
              components={renderers}
            >
              {post.content}
            </ReactMarkdown>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogPost;
