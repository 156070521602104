import React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import "../../css/Blog.css";

const BlogSidebar = ({
  categories,
  tags,
  selectedCategory,
  selectedTags,
  onCategorySelect,
  onTagSelect,
}) => {
  // Sort categories and tags alphabetically
  const sortedCategories = [...categories].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const sortedTags = [...tags].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <h5>Categories</h5>
      <ListGroup className="mb-4 blog-categories">
        <ListGroup.Item
          action
          active={!selectedCategory}
          onClick={() => onCategorySelect(null)}
        >
          All Posts
        </ListGroup.Item>
        {sortedCategories.map((category) => (
          <ListGroup.Item
            key={category.id}
            action
            active={category.slug === selectedCategory}
            onClick={() => onCategorySelect(category.slug)}
          >
            {category.name}
            {/* <Badge bg="secondary" className="float-end">
              {category.post_count}
            </Badge> */}
          </ListGroup.Item>
        ))}
      </ListGroup>

      <h5>Tags</h5>
      <div className="blog-tags mb-4">
        {sortedTags.map((tag) => (
          <Badge
            key={tag.id}
            bg={selectedTags.includes(tag.slug) ? "primary" : "secondary"}
            className="me-2 mb-2 tag-badge"
            style={{ cursor: "pointer" }}
            onClick={() => onTagSelect(tag.slug)}
          >
            {tag.name}
          </Badge>
        ))}
      </div>
    </>
  );
};

export default BlogSidebar;
