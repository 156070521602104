/**
 * @module ArxivButton
 * @description This module defines the ArxivButton component for the portfolio application.
 * The ArxivButton component is a functional React component that renders a button with the ArXiv logo,
 * which links to a specified ArXiv paper.
 *
 * The component uses React Bootstrap's Button component for styling and layout.
 * The ArXiv logo is loaded using the PUBLIC_URL environment variable to ensure the correct path.
 * The button opens the link in a new tab and uses "noopener noreferrer" for security reasons.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.link - The URL of the ArXiv paper to link to.
 * @returns {JSX.Element} The rendered ArxivButton component.
 */

import React from "react";
import { Button } from "react-bootstrap";
import "../../../css/Homepage.css"; // Ensure this file contains the updated styles

/**
 * ArxivButton component that renders a button linking to an ArXiv paper.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.link - The URL of the ArXiv paper to link to.
 * @returns {JSX.Element} The rendered ArxivButton component.
 */
const ArxivButton = ({ link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Button className="arxiv-btn">
        <img
          src={`${process.env.PUBLIC_URL}/Assets/images/arxiv.png`}
          alt="ArXiv"
          style={{
            width: "36px", // Adjusted width for the Arxiv logo
            height: "18px",
            objectFit: "contain",
          }}
        />
      </Button>
    </a>
  );
};

export default ArxivButton;
