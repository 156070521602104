// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-section {
  text-align: left;
  padding: 50px;
}

.navbar {
  margin-bottom: 20px;
}

.custom-navbar {
  background-color: #ffffff;
  margin-top: 2em;
}

.navbar-toggler {
  border: none; /* Style the toggle button if needed */
}

.logo {
  max-width: 100%; /* Ensures the logo is responsive */
}

/* Ensure that the entire app takes up at least the full height of the viewport */
.app-container {
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  overflow: hidden; /* Prevents content from overflowing */
}
`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,YAAY,EAAE,sCAAsC;AACtD;;AAEA;EACE,eAAe,EAAE,mCAAmC;AACtD;;AAEA,iFAAiF;AACjF;EACE,iBAAiB,EAAE,yBAAyB;EAC5C,aAAa;EACb,sBAAsB,EAAE,8BAA8B;EACtD,gBAAgB,EAAE,sCAAsC;AAC1D","sourcesContent":[".home-section {\n  text-align: left;\n  padding: 50px;\n}\n\n.navbar {\n  margin-bottom: 20px;\n}\n\n.custom-navbar {\n  background-color: #ffffff;\n  margin-top: 2em;\n}\n\n.navbar-toggler {\n  border: none; /* Style the toggle button if needed */\n}\n\n.logo {\n  max-width: 100%; /* Ensures the logo is responsive */\n}\n\n/* Ensure that the entire app takes up at least the full height of the viewport */\n.app-container {\n  min-height: 100vh; /* Full viewport height */\n  display: flex;\n  flex-direction: column; /* Stack elements vertically */\n  overflow: hidden; /* Prevents content from overflowing */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
