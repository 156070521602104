import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BlogList from "./BlogList";
import BlogSidebar from "./BlogSidebar";

const Blog = () => {
  const [categories, setCategories] = useState([]);
  const [allTags, setAllTags] = useState([]); // Store all tags
  const [filteredTags, setFilteredTags] = useState([]); // Store filtered tags
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Parse category and tags from URL on component mount and when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryFromUrl = params.get("category");
    const tagsFromUrl = params.get("tags")?.split(",").filter(Boolean) || [];
    setSelectedCategory(categoryFromUrl);
    setSelectedTags(tagsFromUrl);
  }, [location]);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, tagsResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/blogs/categories/`
          ),
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/blogs/tags/`),
        ]);
        setCategories(categoriesResponse.data);
        setAllTags(tagsResponse.data);
        setFilteredTags(tagsResponse.data); // Initially show all tags
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Update available tags when category changes
  useEffect(() => {
    const fetchTagsForCategory = async () => {
      try {
        if (selectedCategory) {
          // Fetch posts for the selected category
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/blogs/`,
            { params: { category: selectedCategory } }
          );

          // Get unique tags from the posts
          const tagsInCategory = new Set();
          response.data.forEach((post) => {
            post.tags.forEach((tag) => {
              tagsInCategory.add(tag.slug);
            });
          });

          // Filter all tags to only show those used in the category
          const filteredTags = allTags.filter((tag) =>
            tagsInCategory.has(tag.slug)
          );
          setFilteredTags(filteredTags);
        } else {
          // If no category is selected, show all tags
          setFilteredTags(allTags);
        }
      } catch (error) {
        console.error("Error fetching tags for category:", error);
      }
    };

    fetchTagsForCategory();
  }, [selectedCategory, allTags]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedTags([]); // Clear selected tags when changing category
    if (category) {
      navigate(`/blogs?category=${category}`);
    } else {
      navigate("/blogs");
    }
  };

  const handleTagSelect = (tag) => {
    let newTags;
    if (selectedTags.includes(tag)) {
      newTags = selectedTags.filter((t) => t !== tag);
    } else {
      newTags = [...selectedTags, tag];
    }
    setSelectedTags(newTags);

    const params = new URLSearchParams();
    if (selectedCategory) params.set("category", selectedCategory);
    if (newTags.length > 0) params.set("tags", newTags.join(","));

    navigate(`/blogs?${params.toString()}`);
  };

  if (loading) {
    return (
      <Container className="mt-4 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <Row>
        <Col md={3}>
          <BlogSidebar
            categories={categories}
            tags={filteredTags} // Pass filtered tags instead of all tags
            selectedCategory={selectedCategory}
            selectedTags={selectedTags}
            onCategorySelect={handleCategorySelect}
            onTagSelect={handleTagSelect}
          />
        </Col>
        <Col md={9}>
          <BlogList
            selectedCategory={selectedCategory}
            selectedTags={selectedTags}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Blog;
