/**
 * @module Footer
 * @description This module defines the Footer component for the portfolio application.
 * The Footer component is a functional React component that renders a footer section
 * with the current year and the author's name.
 *
 * The current year is dynamically retrieved using JavaScript's Date object.
 * The component also imports a CSS file for styling.
 */

import React from "react";
import "../../css/Footer.css";

/**
 * Footer component that renders the footer section of the application.
 *
 * @component
 * @returns {JSX.Element} The rendered footer component.
 */
const Footer = () => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <p>&copy; {currentYear} Kaustubh Harapanahalli</p>
    </div>
  );
};

export default Footer;
