/**
 * @module Projects
 * @description This module defines the Projects component for the portfolio application.
 * The Projects component is a functional React component that renders a section of the portfolio
 * dedicated to showcasing various projects.
 *
 * The component uses a simple section tag styled with CSS for layout and styling.
 * It displays a heading for the projects section.
 *
 * @component
 * @returns {JSX.Element} The rendered Projects component.
 */

import React from "react";

/**
 * Projects component that renders a section for showcasing projects.
 *
 * @component
 * @returns {JSX.Element} The rendered Projects component.
 */
const Projects = () => {
  return (
    <section className="Projects-section">
      <h1>Projects</h1>
    </section>
  );
};

export default Projects;
