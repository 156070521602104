import React from "react";

const Projects = () => {
  return (
    <section className="Projects-section">
      <h1>Projects</h1>
    </section>
  );
};

export default Projects;
