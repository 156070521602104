// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  text-align: center;
  padding-bottom: 10px; /* Distance from the bottom of the page */
  margin-top: auto; /* Push the footer to the bottom */
}

.footer p {
  margin: 0;
  font-size: 0.9rem; /* Optional: smaller font size */
  color: #333; /* Optional: dark gray text */
}
`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB,EAAE,yCAAyC;EAC/D,gBAAgB,EAAE,kCAAkC;AACtD;;AAEA;EACE,SAAS;EACT,iBAAiB,EAAE,gCAAgC;EACnD,WAAW,EAAE,6BAA6B;AAC5C","sourcesContent":[".footer {\n  text-align: center;\n  padding-bottom: 10px; /* Distance from the bottom of the page */\n  margin-top: auto; /* Push the footer to the bottom */\n}\n\n.footer p {\n  margin: 0;\n  font-size: 0.9rem; /* Optional: smaller font size */\n  color: #333; /* Optional: dark gray text */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
