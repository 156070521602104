/**
 * @module SlidesButton
 * @description This module defines the SlidesButton component for the portfolio application.
 * The SlidesButton component is a functional React component that renders a button with an icon,
 * which links to a specified file, typically a presentation or slides document.
 *
 * The component uses a simple anchor tag styled with CSS for layout and styling.
 * The icon is loaded using the PUBLIC_URL environment variable to ensure the correct path.
 * The button opens the link in a new tab and uses "noopener noreferrer" for security reasons.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.file - The URL of the file to link to. This can be an absolute URL or a relative path.
 * @returns {JSX.Element} The rendered SlidesButton component.
 */

import React from "react";
import "../../../css/Homepage.css"; // Ensure this CSS file contains the updated styles

/**
 * SlidesButton component that renders a button linking to a file, typically a presentation or slides.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.file - The URL of the file to link to.
 * @returns {JSX.Element} The rendered SlidesButton component.
 */
const SlidesButton = ({ file }) => {
  /**
   * Constructs the full URL for the file.
   * If the file URL is already absolute, it returns the URL as is.
   * Otherwise, it prepends the base API URL to the relative path.
   *
   * @param {string} fileUrl - The file URL to process.
   * @returns {string} The full URL to the file.
   */
  const getFileUrl = (fileUrl) => {
    if (!fileUrl) return "";
    if (fileUrl.startsWith("http")) return fileUrl;
    return `${process.env.REACT_APP_BASE_API_URL}${fileUrl}`;
  };

  return (
    <a
      href={getFileUrl(file)}
      target="_blank"
      rel="noopener noreferrer"
      className="ppt-btn"
    >
      <img
        src={`${process.env.PUBLIC_URL}/Assets/images/pdf.png`}
        alt="Slides"
        style={{ width: "18px", height: "18px", marginRight: "5px" }}
      />
      <span>Slides</span>
    </a>
  );
};

export default SlidesButton;
