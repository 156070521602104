/**
 * @module NavBar
 * @description This module defines the NavBar component for the portfolio application.
 * The NavBar component is a functional React component that renders a navigation bar
 * with links to various sections of the application, such as About, Blog, Publications, and Portfolio.
 *
 * The component uses React Bootstrap's Navbar, Nav, and Container components for layout and styling.
 * It also utilizes the Link component from react-router-dom for client-side navigation.
 *
 * The NavBar component dynamically displays a "Publications" tab based on the number of publications
 * retrieved from the backend API. If the total number of publications is greater than 3, the tab is shown.
 *
 * The logo in the navigation bar is loaded using the PUBLIC_URL environment variable to ensure the correct path.
 *
 * @component
 * @returns {JSX.Element} The rendered NavBar component.
 */

import axios from "axios";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "../../css/App.css";

/**
 * NavBar component that renders the navigation bar of the application.
 *
 * @component
 * @returns {JSX.Element} The rendered NavBar component.
 */
const NavBar = () => {
  // State to determine whether to show the Publications tab
  const [showPublicationsTab, setShowPublicationsTab] = useState(false);

  // Effect to check the number of publications and update the state
  useEffect(() => {
    const checkPublicationsCount = async () => {
      try {
        // Fetch the total number of publications from the backend API
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/homepage/`
        );
        // Show the Publications tab if there are more than 3 publications
        setShowPublicationsTab(response.data.total_publications > 3);
      } catch (error) {
        console.error("Error checking publications count:", error);
      }
    };

    checkPublicationsCount();
  }, []);

  return (
    <Navbar sticky="top" expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            // Use process.env.PUBLIC_URL for the logo path
            src={`${process.env.PUBLIC_URL}/Assets/images/logo.svg`}
            alt="logo"
            width="30"
            height="30"
            className="d-inline-block align-top logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" className="ms-2">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/blogs" className="ms-2">
              Blog
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/projects" className="ms-2">
              Projects
            </Nav.Link> */}
            {showPublicationsTab && (
              <Nav.Link as={Link} to="/publications" className="ms-2">
                Publications
              </Nav.Link>
            )}
            <Nav.Link as={Link} to="/portfolio" className="ms-2">
              Portfolio
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
