/**
 * @module GithubButton
 * @description This module defines the GithubButton component for the portfolio application.
 * The GithubButton component is a functional React component that renders a button with the GitHub logo,
 * which links to a specified GitHub repository.
 *
 * The component uses React Bootstrap's Button component for styling and layout.
 * The GitHub logo is loaded using the PUBLIC_URL environment variable to ensure the correct path.
 * The button opens the link in a new tab and uses "noopener noreferrer" for security reasons.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.link - The URL of the GitHub repository to link to.
 * @returns {JSX.Element} The rendered GithubButton component.
 */

import React from "react";
import { Button } from "react-bootstrap";
import "../../../css/Homepage.css"; // Ensure this file contains the updated styles

/**
 * GithubButton component that renders a button linking to a GitHub repository.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.link - The URL of the GitHub repository to link to.
 * @returns {JSX.Element} The rendered GithubButton component.
 */
const GithubButton = ({ link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Button className="github-btn">
        <img
          src={`${process.env.PUBLIC_URL}/Assets/images/github.png`}
          alt="GitHub"
          style={{ width: "18px", height: "18px" }} // Adjusted size for the GitHub logo
        />
        <span>Code</span>
      </Button>
    </a>
  );
};

export default GithubButton;
