/**
 * @module PosterButton
 * @description This module defines the PosterButton component for the portfolio application.
 * The PosterButton component is a functional React component that renders a button with a PDF icon,
 * which links to a specified poster file. The file can be either an absolute URL or a relative path.
 *
 * The component uses a custom CSS class for styling and layout. The PDF icon is loaded using the
 * PUBLIC_URL environment variable to ensure the correct path. The button opens the link in a new tab
 * and uses "noopener noreferrer" for security reasons.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.file - The URL or relative path of the poster file to link to.
 * @returns {JSX.Element} The rendered PosterButton component.
 */

import React from "react";
import "../../../css/Homepage.css";

/**
 * PosterButton component that renders a button linking to a poster file.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.file - The URL or relative path of the poster file to link to.
 * @returns {JSX.Element} The rendered PosterButton component.
 */
const PosterButton = ({ file }) => {
  /**
   * Constructs the full URL for the poster file.
   *
   * @param {string} fileUrl - The URL or relative path of the poster file.
   * @returns {string} The full URL of the poster file.
   */
  const getFileUrl = (fileUrl) => {
    if (!fileUrl) return "";
    // If it's already an absolute URL, return it
    if (fileUrl.startsWith("http")) return fileUrl;
    // Otherwise, prepend the API URL
    return `${process.env.REACT_APP_BASE_API_URL}${fileUrl}`;
  };

  return (
    <a
      href={getFileUrl(file)}
      target="_blank"
      rel="noopener noreferrer"
      className="ppt-btn"
    >
      <img
        src={`${process.env.PUBLIC_URL}/Assets/images/pdf.png`}
        alt="Poster"
        style={{ width: "18px", height: "18px", marginRight: "5px" }}
      />
      <span>Poster</span>
    </a>
  );
};

export default PosterButton;
