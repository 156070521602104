import React from "react";
import { Route, Routes } from "react-router-dom";
import Blog from "./components/Blog/Blog";
import BlogPost from "./components/Blog/BlogPost";
import Footer from "./components/common/Footer"; // Adjust the path to where you create the Footer component
import NavBar from "./components/common/Navbar";
import Home from "./components/homepage/Homepage";
import Projects from "./components/portfolio/Projects"; // Import your Projects component
import Publications from "./components/portfolio/Publications"; // Import your Publications component
import Resume from "./components/portfolio/Resume"; // Import your Resume component

function App() {
  return (
    <div className="app-container">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:slug" element={<BlogPost />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/portfolio" element={<Resume />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
