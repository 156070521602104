/**
 * @module LoadingState
 * @description This module defines the LoadingState component for the portfolio application.
 * The LoadingState component is a functional React component that renders a loading spinner
 * to indicate that content is being loaded.
 *
 * The component uses React Bootstrap's Container and Spinner components for layout and styling.
 * The spinner is centered both vertically and horizontally within the container.
 *
 * @component
 * @returns {JSX.Element} The rendered loading state component.
 */

import React from "react";
import { Container, Spinner } from "react-bootstrap";

/**
 * LoadingState component that renders a loading spinner.
 *
 * @component
 * @returns {JSX.Element} The rendered loading state component.
 */
const LoadingState = () => {
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "50vh" }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
};

export default LoadingState;
